<template>
  <div id="login-wrapper">
    <CContainer class="min-vh-100 d-flex flex-column align-items-center justify-content-center">
      <CRow class="w-100 justify-content-center" style="margin-bottom: 12px">
        <img src="/img/logo-wht.png" />
      </CRow>
      <CRow class="w-100 justify-content-center">
        <CCol md="6" sm="8">
          <CCard class="mx-4 mb-0 p-4 login-input">
            <CCardBody>
              <CForm>
                <CInput v-on:keyup.native.enter="handleSubmit"
                        v-model="username"
                        placeholder="Username" >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput v-on:keyup.native.enter="handleSubmit"
                        v-model="password"
                        placeholder="Password"
                        type="password" >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                <CRow>
                  <CCol col="6">
                    <CButton color="primary" class="px-4" @click="handleSubmit" :disabled="isLoading" style="min-width: 175px;">
                      <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                      {{ btnTxt }}
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow class="w-100 justify-content-center" style="margin-top: 10px">
        <CAlert
                color="danger"
                :show.sync="currentAlertCounter"
                closeButton
        >
          {{ errMsg }}
          <CProgress
                  :max="5"
                  :value="currentAlertCounter"
                  height="3px"
                  color="danger"
                  animate
          />
        </CAlert>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
  export default {
    name: 'Login',
    data(){
      return {
        username : "",
        password : "",
        isLoading : false,
        errMsg: "",
        currentAlertCounter: 0
      }
    },
    computed: {
      btnTxt: function(){
        return (this.isLoading ? 'Please Wait...' : 'Login');
      }
    },
    methods : {
      handleSubmit(e){
        e.preventDefault();
        if (this.password.length > 0) {
          this.isLoading = true;
          this.$http.post(this.$config.gmapi + '/Login', {
            username: this.username,
            password: this.password
          }).then(response => {
            if (response.data.status) {
              localStorage.setItem('username',response.data.account.username);
              localStorage.setItem('jwt',response.data.account.token);
              localStorage.setItem('roles', response.data.account.roles);

              if (localStorage.getItem('env') == null) {
                localStorage.setItem('env', 'qa');
              }

              if (localStorage.getItem('jwt') != null){
                this.$emit('loggedIn');
                if(this.$route.params.nextUrl != null){
                  this.$router.push(this.$route.params.nextUrl)
                } else {
                  this.$router.push('dashboard')
                }
              }
            } else {
              this.errMsg = "Username or Password incorrect";
              this.currentAlertCounter = 5;
            }
          }).catch(function (error) {
            this.errMsg = error == null ? "SSO returned " + error.statusText + " (" + error.status + ")" : "Unknown SSO Error";
            this.currentAlertCounter = 5;
          }).finally(function () {
            this.isLoading = false;
          });
        }
      }
    }
  }
</script>
